<script>
export default {
  setup() {
    const { t } = useI18n();
    const auth = useAuthStore();
    const layout = useLayoutStore();
    const helper = useHelperStore();
    const storage = useResponseStore();
    const version = useVersionStore();
    const module = useModuleStore();
    const config = useConfigurationStore();
    return { auth, layout, helper, storage, version, t, module, config };
  },
  mounted() {
    let lang = "id";
    if (this.storage.getStorage("locale")) {
      lang = this.storage.getStorage("locale");
      this.setLocale(lang);
    } else {
      this.setLocale(lang);
    }
  },
  computed: {
    appVer() {
      if (this.version.version) return this.version.version.version;
      return null;
    },
  },
  methods: {
    async endSession() {
      await this.$swal({
        title: this.t("End This Session?"),
        icon: "warning",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.auth.logout();
          this.$router.replace("/auths/login");
        }
      });
    },
    setLocale(payload) {
      this.$i18n.locale = payload;
      this.storage.setStorage("locale", payload);
      if (document.getElementById("dropdown-lang"))
        UIKit.dropdown("#dropdown-lang").hide(false);
    },
  },
};
</script>

<template>
  <div class="navigation-top uk-background-default">
    <div
      class="uk-navbar-container uk-navbar-transparent"
      uk-navbar="mode: click"
    >
      <div class="uk-navbar-left">
        <ul class="uk-navbar-nav ids-navbar-left" style="padding-top: 2px">
          <li class="uk-visible@s">
            <div @click="$router.replace('/')" class="logo uk-flex pointer">
              <img src="/images/logo.png" alt="PHKT Logo" />
              <img
                src="/images/vorteks.png"
                alt="Logo"
                style="margin-left: 10px"
              />
            </div>
          </li>
          <li class="uk-visible@s">
            <span class="uk-dark uk-text-small brand uk-visible@s">
              <b>{{ config.get("app.name") }}</b>
              <br /><small>Ver. {{ appVer }}</small>
            </span>
          </li>
          <li class="uk-hidden@s">
            <button
              v-if="auth.user"
              class="uk-button uk-button-small uk-button-default uk-padding-remove uk-flex uk-flex-middle"
            >
              <img
                v-if="auth.user?.profile?.media?.mediaUrl"
                class="initial-name bg-color-x"
                :src="auth.user?.profile?.media?.mediaUrl"
                :alt="auth.user.name"
              />
              <span v-else class="initial-name bg-color-x uk-text-uppercase">
                {{ auth.user.nameInitial }}
              </span>
              <div class="uk-text-left">
                <div
                  class="uk-dark uk-text-small uk-margin-small-left uk-text-capitalize"
                >
                  {{ $t("Welcome") }}
                </div>
                <div
                  class="uk-dark uk-text-small uk-margin-small-left uk-text-capitalize uk-text-bold"
                >
                  {{ auth.user.name }}
                </div>
              </div>
            </button>
            <div uk-dropdown="mode: click;" class="uk-width-small">
              <template v-if="auth.user && auth.user?.profile?.media?.mediaUrl">
                <img
                  class="profile-picture"
                  :alt="auth.user.name"
                  :src="auth.user?.profile?.media?.mediaUrl"
                />
                <hr class="uk-margin-small-bottom uk-margin-small-top" />
              </template>

              <template v-if="auth.roles">
                <div class="uk-width-1-1 uk-flex uk-flex-center">
                  <div
                    v-for="val in auth.roles"
                    :key="val"
                    class="uk-badge uk-text-capitalize"
                  >
                    {{ val }}
                  </div>
                </div>
                <hr class="uk-margin-remove-bottom uk-margin-small-top" />
              </template>
              <ul class="uk-nav uk-dropdown-nav">
                <li>
                  <RouterLink
                    to="/dashboard/profile"
                    class="uk-padding-remove-vertical"
                  >
                    <span class="navigation-side-icon material-icons md-20">
                      <EditIcon />
                    </span>
                    <span class="navigation-side-label">{{
                      $t("Profile")
                    }}</span>
                  </RouterLink>
                </li>
                <li class="uk-nav-divider"></li>
                <li>
                  <button
                    @click="endSession"
                    class="uk-margin-auto-vertical uk-button uk-button-primary red uk-width-1-1"
                  >
                    <span class="navigation-side-icon material-icons md-20">
                      <LogoutIcon />
                    </span>
                    <span class="navigation-side-label">{{
                      $t("Logout")
                    }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="uk-navbar-right">
        <ul class="uk-navbar-nav" style="gap: 0px">
          <li v-if="module.check('company') === true">
            <!-- <CompanySelector /> -->
          </li>
          <li>
            <DashboardNotification />
          </li>
          <li v-if="auth.user" class="uk-visible@s">
            <button
              class="uk-button uk-button-small uk-button-default uk-padding-remove uk-flex uk-flex-middle"
            >
              <span
                class="uk-dark uk-text-small uk-margin-small-right uk-text-capitalize uk-visible@s"
              >
                {{ auth.user.name }}
              </span>
              <img
                v-if="auth.user?.profile?.media?.mediaUrl"
                class="initial-name bg-color-x"
                :src="auth.user?.profile?.media?.mediaUrl"
                :alt="auth.user.name"
              />
              <span v-else class="initial-name bg-color-x uk-text-uppercase">
                {{ auth.user.nameInitial }}
              </span>
            </button>
            <div uk-dropdown="mode: click;" class="uk-width-small">
              <template v-if="auth.user && auth.user?.profile?.media?.mediaUrl">
                <img
                  class="profile-picture"
                  :alt="auth.user.name"
                  :src="auth.user?.profile?.media?.mediaUrl"
                />
                <hr class="uk-margin-small-bottom uk-margin-small-top" />
              </template>

              <template v-if="auth.roles">
                <div class="uk-width-1-1 uk-flex uk-flex-center">
                  <div
                    v-for="val in auth.roles"
                    :key="val"
                    class="uk-badge uk-text-capitalize"
                  >
                    {{ val }}
                  </div>
                </div>
                <hr class="uk-margin-remove-bottom uk-margin-small-top" />
              </template>
              <ul class="uk-nav uk-dropdown-nav">
                <li>
                  <RouterLink
                    to="/dashboard/profile"
                    class="uk-padding-remove-vertical"
                  >
                    <span class="navigation-side-icon material-icons md-20">
                      <EditIcon />
                    </span>
                    <span class="navigation-side-label">{{
                      $t("Profile")
                    }}</span>
                  </RouterLink>
                </li>
                <li class="uk-nav-divider"></li>
                <li>
                  <button
                    @click="endSession"
                    class="uk-margin-auto-vertical uk-button uk-button-primary red uk-width-1-1"
                  >
                    <span class="navigation-side-icon material-icons md-20">
                      <LogoutIcon />
                    </span>
                    <span class="navigation-side-label">{{
                      $t("Logout")
                    }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="auth.user && $i18n.availableLocales.length > 0">
            <button
              class="uk-button uk-button-small uk-button-default uk-padding-remove uk-flex uk-flex-middle"
            >
              <div class="initial-name bg-color-x">
                <LanguageIcon size="20" />
              </div>
            </button>
            <div id="dropdown-lang" uk-dropdown="mode: click;">
              <button
                v-for="(locale, key) in $i18n.availableLocales"
                @click="setLocale(locale)"
                v-bind:key="key"
                class="uk-margin-auto-vertical uk-button uk-button-default uk-text-left uk-width-1-1 uk-flex uk-flex-middle"
                :class="locale === $i18n.locale ? 'uk-text-bold' : ''"
              >
                <span class="navigation-side-label">
                  {{ locale.toUpperCase() }} -
                  {{ helper.getLocaleName(locale) }}
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
